/* eslint-disable react/no-unescaped-entities */

import { Link } from 'gatsby'
import React from 'react'
import Block from '../components/Block'
import Page from '../components/Page'

export const TermsAndConditions = () => (
  <Page>
    <Block size="small">
      <h1>Terms of Service</h1>

      <p>
        <i>Last updated: 25 April 2024</i>
      </p>

      <p>
        These Terms of Service (“<strong>Agreement</strong>”) are applicable to
        the agreement between the Customer and Swarmia Oy (Business ID
        3094736-9), a limited liability company incorporated and existing under
        the laws of Finland, having its principal place of business at
        Lapinlahdenkatu 16, 00180 Helsinki, FINLAND (“<strong>Swarmia</strong>
        ”).
      </p>

      <p>
        Any use of the Service provided by Swarmia is always subject to this
        Agreement, and other terms as may be applicable. By accepting this
        Agreement the Customer acknowledges and agrees that the Customer's use
        of the Service is subject to this Agreement.
      </p>

      <ol className="nested-numbered-list">
        <li>
          <h2>Definitions</h2>
          <ol>
            <li>
              “<strong>Affiliate</strong>” means an entity that controls, is
              controlled by or is under common control with another entity,
              where “control” refers to ownership of more than fifty percent
              (50%) of the outstanding shares or securities representing the
              right to vote for the election of directors or other managing
              authority of another entity;
            </li>
            <li>
              “<strong>Confidential Information</strong>” shall have the meaning
              ascribed to it in Section 10;
            </li>
            <li>
              “<strong>Customer</strong>” means the company or other entity
              specified in the order for the Service which is submitted to
              Swarmia and accepted by Swarmia;
            </li>
            <li>
              “<strong>Customer Material</strong>” means data or material
              uploaded by the Customer or the Customer's Affiliate or their
              contractors or consultants to the Service or otherwise
              communicated to or made available to Swarmia on behalf of the
              Customer for the provision of the Service or other material which
              is separately defined as Customer Material by the Parties;
            </li>
            <li>
              “<strong>Customer Systems</strong>” means own devices, systems,
              applications, connections and software of the Customer or its
              Affiliates integrated, used or otherwise made available to Swarmia
              in connection with the Service;
            </li>
            <li>
              “<strong>Intellectual Property Rights</strong>” means (i) patents,
              inventions, designs, copyright and related rights, database
              rights, trade marks and related goodwill, trade names (whether
              registered or unregistered) and rights to apply for registration;
              (ii) proprietary rights in domain names; (iii) knowhow and
              confidential information; (iv) applications, extensions and
              renewals in relation to any of these rights; and (v) all other
              rights of a similar nature or having an equivalent effect anywhere
              in the world; and
            </li>
            <li>
              “<strong>Service</strong>” means Swarmia's engineering performance
              platform as described at Swarmia's Web Site at{' '}
              <a className="high-visibility" href="https://www.swarmia.com/">
                https://www.swarmia.com
              </a>
              .
            </li>
          </ol>
        </li>

        <li>
          <h2>The Service</h2>
          <ol>
            <li>
              The Service is available for authorized use{' '}
              <a className="high-visibility" href="https://app.swarmia.com/">
                https://app.swarmia.com/
              </a>
              .
            </li>
            <li>
              Swarmia reserves the right to make changes to the Service at any
              time. If a change has a material adverse effect on the agreed
              functionality of the Service or the agreed service levels or a
              material adverse effect for the Customer regarding other aspects
              of the Agreement, Swarmia shall inform the Customer of such change
              at least thirty (30) days before the effective date of the change.
              In such case the Customer shall have the right to terminate this
              Agreement as of the effective date of the change subject to a
              fourteen (14) days' prior written notice. The termination notice
              must be delivered to Swarmia in writing, email being sufficient.
            </li>
            <li>
              Swarmia shall always be entitled but shall have no obligation to
              make such changes to the Service that (a) concern or relate to the
              production environment of the Service and do not have a material
              adverse effect on the agreed contents of the Service, (b) are
              necessary to prevent any occurrence of data security risk to the
              Service, or (c) result from compliance with law. For the avoidance
              of any doubt, the Customer shall not have a right to terminate
              this Agreement due to changes set out in this Section 2.3.
            </li>
            <li>
              Unless expressly set out to the contrary, the Service is not
              subject to any particular service levels, and is provided to the
              Customer strictly on an “as is” and “as available” basis, and
              Swarmia makes no representation or does not warrant the
              availability of the Service at any particular time.
            </li>
          </ol>
        </li>

        <li>
          <h2>Right to Use the Service</h2>
          <ol>
            <li>
              Subject to Customer's due payment of the applicable Fees and
              compliance with this Agreement, Swarmia hereby grants to the
              Customer and its Affiliates, including their employees,
              consultants and contractors, a non­exclusive, non-transferable,
              non-sub licensable, limited right and license to use the Service
              during the term and subject to the terms and conditions of this
              Agreement. The Customer is responsible for acts and omissions of
              its Affiliates (and their consultants and contractors) as for its
              own under this Agreement.
            </li>
            <li>
              The Customer's right to use the Service is limited to the
              Customer's and its Affiliates' internal business operations and
              internal business purposes only.
            </li>
            <li>
              The Customer may not use the Service for the benefit of any third
              party or grant any third party, except its Affiliates, consultants
              and contractors, access to use the Service.
            </li>
          </ol>
        </li>

        <li>
          <h2>Customer's Obligations</h2>
          <ol>
            <li>
              The Customer shall be responsible for providing appropriate access
              rights to Swarmia and limiting access to Customer Systems,
              Customer Material and Personal Data as strictly necessary for the
              purpose the Service.
            </li>
            <li>
              The Customer shall be responsible for Customer Systems and their
              functionality used in connection with the Service.
            </li>
            <li>
              The Customer shall be responsible for the protection of data
              communications and Customer Systems and costs for communications
              and other comparable costs related to use of the Service. The
              Customer shall be responsible for preparing Customer Systems to
              meet the operating environment specifications specified by Swarmia
              and for ensuring that the Service fulfills the Customer's intended
              purpose.
            </li>
            <li>
              The Customer shall be solely responsible for making its own
              back-ups of any data, including Customer Material. Except as
              otherwise agreed, Swarmia shall not be responsible for any loss,
              destruction or alteration of any Customer Material or any related
              data or for any resulting costs and damages, including any costs
              related to the recreating of such Customer Material or any related
              data.
            </li>
            <li>
              The Customer shall ensure that only its own and its Affiliates'
              users are able to use the Service in accordance with this
              Agreement and licenses granted to the Customer under this
              Agreement. The Customer shall be responsible for ensuring that its
              users maintain their user names and passwords diligently and do
              not disclose them to third parties. The Customer shall be
              responsible for all use of the Service when such Customer's user
              names and passwords are used.
            </li>
          </ol>
        </li>

        <li>
          <h2>Suspension of Services</h2>
          <ol>
            <li>
              Swarmia shall have the right to suspend the provision of the
              Service for a reasonable period of time if this is necessary in
              order to perform installation, change or maintenance work in
              respect of the Service or if such suspension results from
              installation, change or maintenance work in respect of public
              communication networks. In the event the Service is suspended
              under this Section 5.1 for more than twenty-four (24) hours in a
              single calendar month during the term of this Agreement, Swarmia
              shall credit the Customer the full monthly fee for such affected
              Service, which shall be applied to the Customer's next monthly
              invoice. If there are no upcoming invoices, Swarmia will refund
              the full monthly fee by using a refund method mutually agreed
              between the Parties. For the avoidance of doubt, the possible
              suspensions are informed to the Customer via the Service on a best
              effort basis after the suspension has been verified by Swarmia.
              Any unplanned outages shall be resolved by Swarmia without undue
              delay.
            </li>
            <li>
              Swarmia shall also have the right to suspend the provision of the
              Service due to a data security risk to the Service or if
              applicable laws or administrative orders require Swarmia to do so.
            </li>
            <li>
              Swarmia shall always have the right to suspend the Service, if the
              Customer is in default with its payment of any Fees due under this
              Agreement and does not pay such Fees despite a request to pay
              within fourteen (14) days from the date of such request. The
              suspension may be continued until the Customer has paid all Fees
              due under this Agreement.
            </li>
            <li>
              Swarmia may suspend the Service if the Customer ceases to conduct
              its business for any other reason than a transfer referred to in
              Section 16.3, is adjudicated in bankruptcy or liquidation or
              corporate restructuring, is found insolvent in recovery
              proceedings or if a material default in payment has been
              registered to the Customer.
            </li>
            <li>
              Swarmia shall have the right to deny the Customer access to the
              Service, if Swarmia reasonably suspects that the Customer uses the
              Service contrary to this Agreement, applicable laws or
              administrative orders, or in a manner that unreasonably burdens
              the Service or otherwise jeopardizes the provision of the Service
              to other users. Swarmia may deny such access to the Service
              without first hearing the Customer.
            </li>
          </ol>
        </li>

        <li>
          <h2>Intellectual Property Rights</h2>
          <ol>
            <li>
              All rights, title and interest, including all Intellectual
              Property Rights in and to the Service and any changes thereto
              shall belong exclusively to Swarmia or its licensors. Except for
              the express license to use the Service granted to the Customer
              under and in accordance with the terms and conditions of this
              Agreement, the Customer shall have no and shall not by virtue of
              this Agreement obtain any rights, license or interests in and to
              the Service or any Intellectual Property Rights pertaining
              thereto.
            </li>
            <li>
              All rights, title and interest, including all Intellectual
              Property Rights in and to the Customer Systems or Customer
              Material shall belong exclusively to the Customer or a third
              party. The Customer grants to Swarmia a non-exclusive right and
              license to use the Customer Systems and Customer Material during
              the term of this Agreement for the purpose of providing the
              Service to the Customer. Swarmia shall have no and shall not by
              virtue of the Agreement obtain any rights, license or interests in
              and to the Customer Systems or Customer Material or any
              Intellectual Property Rights pertaining to the Customer or its
              affiliates except as expressly set forth in this Agreement.
            </li>
            <li>
              The Customer shall be responsible for ensuring that the Customer
              Material does not infringe upon any third party rights or
              applicable laws or regulations.
            </li>
          </ol>
        </li>

        <li>
          <h2>Data Protection</h2>
          <ol>
            <li>
              Swarmia may process personal data as regards the Customer and its
              authorized users, for the purpose of providing, developing and
              marketing the Service. In such case Swarmia is a controller and
              such processing shall be subject to Swarmia's{' '}
              <Link className="high-visibility" to="/privacy/">
                Privacy Policy
              </Link>{' '}
              as available at Swarmia's Web Site.
            </li>
            <li>
              To the extent Swarmia (or its subcontractor where appropriate)
              processes personal data on behalf of the Customer, such processing
              of personal data is subject to Swarmia's{' '}
              <Link className="high-visibility" to="/dpa/">
                Data Processing Agreement
              </Link>
              .
            </li>
          </ol>
        </li>

        <li>
          <h2>Free Trial Period</h2>
          <ol>
            <li>
              Swarmia may from time to time offer a free trial (
              <strong>“Free Trial Period”</strong>). Each Customer may only use
              this Free Trial Period once.
            </li>
            <li>
              Free Trial Period is subject to the Customer's acceptance of this
              Agreement.
            </li>
            <li>
              Swarmia reserves the right, in its sole discretion, to withdraw or
              to modify this Free Trial Period at any time without prior notice
              and with no liability.
            </li>
            <li>
              To the extent that Swarmia processes any personal data in the
              course of this Free Trial Period, such personal data shall be
              processed in accordance with Swarmia's{' '}
              <Link className="high-visibility" to="/dpa/">
                Data Processing Agreement
              </Link>{' '}
              and Swarmia's{' '}
              <Link className="high-visibility" to="/privacy/">
                Privacy Policy
              </Link>
              .
            </li>
            <li>
              Save for any applicable statutory liabilities, Swarmia shall have
              no liability of any kind, indirect or direct, in any circumstances
              whatever to the Customer or a third party in respect of the
              Service.
            </li>
          </ol>
        </li>

        <li>
          <h2>Fees</h2>
          <ol>
            <li>
              The Fees are set out in Swarmia's price list as applicable from
              time to time.
            </li>
            <li>
              The Fees for the use of the Service shall be invoiced monthly or
              annually on each agreed-upon periodic renewal, as specified in the
              applicable order form or payment checkout page provided by
              Swarmia. Swarmia reserves the right to automatically invoice the
              Customer for the Fees due, provided that such payment method is
              applicable and has been selected by the Customer.
            </li>
            <li>
              Swarmia reserves the right to change the Fees from time to time
              upon thirty (30) days prior notice. In such a case the Customer
              shall have the right to terminate this Agreement as of the
              effective date of the change subject to a fourteen (14) days'
              prior written notice. The termination notice must be delivered to
              Swarmia in writing, email being sufficient.
            </li>
            <li>
              The Fees are exclusive of VAT and any other governmental taxes and
              levies, which Swarmia reserves the right to invoice if applicable
              or required by law. Interest on delayed payments accrues in
              accordance with the Finnish Interest Act (633/1982, as amended).
            </li>
          </ol>
        </li>

        <li>
          <h2>Confidentiality</h2>
          <ol>
            <li>
              Each Party shall keep in confidence all material and information
              received from the other Party and marked as confidential or which
              should be understood to be confidential ("Confidential
              Information"), and may not use such Confidential Information for
              any other purpose than those set forth in this Agreement. The
              Customer's Confidential Information expressly includes (but is not
              limited to) Customer Material and any business, technical or
              financial information related to the Customer or any of its
              Affiliates. The confidentiality obligation shall, however, not
              apply to material and information, (a) which is or later becomes
              generally available or otherwise public; or (b) which the
              receiving Party has received from a third party without any
              obligation of confidentiality; or (c) which was rightfully in the
              possession of the receiving Party prior to receipt of the same
              from the disclosing Party without any obligation of
              confidentiality related thereto; (d) which a Party has
              independently developed without any use of or reference to the
              Confidential Information received from the other Party; or (e)
              which a Party is required to disclose under any mandatory law or
              by order of a court or governmental body of competent
              jurisdiction.
            </li>
            <li>
              Each Party shall promptly upon termination of this Agreement, at
              any time upon the disclosing Party's request, or when the Party no
              longer needs the Confidential Information in question for the
              purpose of performing its obligations or exercising its rights
              under this Agreement, cease using the Confidential Information
              received from the other Party and, unless the Parties separately
              agree on destruction of such Confidential Information, return the
              Confidential Information in question (including all copies and
              reproductions thereof) to the other Party. For the avoidance of
              doubt, returning the Confidential Information shall mean that the
              receiving Party simultaneously deletes the Confidential
              Information from its own systems. Swarmia shall, however, be
              entitled to retain the backup of the Customer Material in
              encrypted format for up to 90 days after which the backup is
              automatically deleted. For the avoidance of doubt, each Party
              shall be entitled to retain the copies required by law or
              administrative orders applicable to such Party.
            </li>
            <li>
              Notwithstanding the confidentiality obligation set forth herein,
              each Party shall be entitled to use the general professional
              skills and experience (in intangible form) acquired in connection
              with the performance of this Agreement.
            </li>
            <li>
              The rights and obligations related to the Confidential Information
              shall survive the termination or cancellation of this Agreement.
            </li>
          </ol>
        </li>

        <li>
          <h2>Disclaimer of Warranties</h2>
          <ol>
            <li>
              EXCEPT AS EXPRESSLY STATED IN THIS AGREEMENT, SWARMIA MAKES NO
              REPRESENTATIONS OR WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY
              OR OTHERWISE, INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY,
              FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. SWARMIA
              DOES NOT WARRANT THAT: (i) THE SERVICE SHALL OPERATE IN
              COMBINATION WITH ANY OTHER SERVICES, SYSTEMS, SOFTWARE, HARDWARE
              OR DATA; (ii) THE SERVICE OR ANY INFORMATION OBTAINED THROUGH THE
              SERVICE SHALL MEET THE CUSTOMER'S REQUIREMENTS OR EXPECTATIONS; OR
              (iii) THE SERVICE SHALL BE UNINTERRUPTED, ERROR-FREE OR
              VIRUS-FREE, OR THAT ERRORS OR DEFECTS THEREIN WILL BE CORRECTED.
              SWARMIA IS NOT RESPONSIBLE FOR THE IMPACT ON THE ACCURACY,
              RELIABILITY, AVAILABILITY AND/OR TIMELINESS OF RESULTS OF FACTORS
              OUTSIDE ITS REASONABLE CONTROL, INCLUDING THE CUSTOMER'S NETWORK
              ISSUES, VERSIONS OF THE CUSTOMER'S APPLICATIONS, CORRUPTED,
              INCOMPLETE AND/OR INTERRUPTED DATA RECEIVED FROM THE CUSTOMER OR
              OTHER PROBLEMS INHERENT IN THE USE OF THE INTERNET AND ELECTRONIC
              COMMUNICATIONS. SWARMIA IS NOT RESPONSIBLE FOR ANY DELAYS,
              DELIVERY FAILURES, OR OTHER DAMAGES RESULTING FROM SUCH PROBLEMS.
            </li>
          </ol>
        </li>

        <li>
          <h2>Indemnity</h2>
          <ol>
            <li>
              The Customer shall indemnify, defend and hold harmless Swarmia,
              its Affiliates and its and their respective directors, officers,
              employees, agents, contractors, successors and assigns from and
              against any and all actions, suits, proceedings, investigations,
              demands, judgments, damages, liability, fines, liens, losses,
              costs and expenses (including reasonable attorneys' fees) arising
              from or relating to: (i) any claim or threat that the Customer
              Material or Customer Systems or the use of the Customer Material
              or Customer Systems in accordance with the terms and conditions of
              this Agreement infringes upon a right of a third party; or (ii)
              the Customer's breach of Section 4. The Customer undertakes to pay
              any damages, costs and expenses caused to Swarmia by any such
              third party claim as well as any damages awarded to a third party
              claimant by a competent court of law or court of arbitration due
              to a reason attributable to the Customer Material. The Customer's
              indemnity obligations under this Section are subject to that: (i)
              Customer is given prompt notice of any such claim; (ii) Swarmia
              provides reasonable co-operation to Customer in the defence and
              settlement of such claim, at Customer's expense; and (iii)
              Customer is given sole authority to defend or settle the claim.
            </li>
            <li>
              Swarmia agrees to defend the Customer and its Affiliates, at its
              own expense, against any third party claims or actions where a
              third party claims that the Service or the use of the Service in
              accordance with terms and conditions of this Agreement infringes
              upon intellectual property rights of a third party valid in the
              European Economic Area (EEA) or the United States. Swarmia
              undertakes to pay any damages, costs and expenses caused to the
              Customer or it's Affiliates by any such third party claim as well
              as any damages awarded to a third party claimant by a competent
              court of law or court of arbitration. Swarmia's indemnity
              obligations under this Section are subject to that:
              <ol>
                <li>Swarmia is given prompt notice of any such claim;</li>
                <li>
                  the Customer provides reasonable co-operation to Swarmia in
                  the defence and settlement of such claim, at Swarmia's
                  expense; and
                </li>
                <li>
                  Swarmia is given sole authority to defend or settle the claim.
                </li>
              </ol>
            </li>
            <li>
              In the defense or settlement of any claim, Swarmia may procure the
              right for the Customer to continue using the Service, replace or
              modify the Service so that it becomes non-infringing or, if such
              remedies are not reasonably available, terminate this Agreement
              without any additional liability or obligation to pay damages or
              other additional costs to the Customer.
            </li>
            <li>
              In no event shall Swarmia be liable to the Customer to the extent
              that the alleged infringement is based on:
              <ol>
                <li>
                  a modification of the Service in accordance with the
                  Customer's instructions; or
                </li>
                <li>
                  the Customer's use of the Service in a manner contrary to the
                  instructions given to the Customer by Swarmia; or
                </li>
                <li>
                  the Customer's use of the Service after notice of the alleged
                  or actual infringement from Swarmia or any appropriate
                  authority.
                </li>
              </ol>
            </li>
          </ol>
        </li>

        <li>
          <h2>Limitation of Liability</h2>
          <ol>
            <li>
              Except as provided in Section 13.3, the total aggregate liability
              of each Party under and in relation to this Agreement shall not
              exceed the amount of Fees (excluding VAT) paid by the Customer to
              Swarmia for the Service during the twelve (12) months immediately
              preceding the event giving rise to liability.
            </li>
            <li>
              Except as provided in Section 13.3, neither Party shall be liable
              for any loss of profits, revenue or business, damages caused due
              to decrease in turnover or production or loss, alteration,
              destruction or corruption of data or indirect, incidental, or
              consequential damages.
            </li>
            <li>
              The foregoing limitations of liability shall not apply to: (i)
              damages caused by willful misconduct or gross negligence; (ii) a
              Party's liability under Section 10 (Confidentiality); or (iii)
              Customer's payment obligations under this Agreement.
            </li>
          </ol>
        </li>

        <li>
          <h2>Term and Termination</h2>
          <ol>
            <li>
              This Agreement shall enter into force when Swarmia has accepted
              the Customer's order for the Service and shall remain in force for
              a fixed term of twelve (12) months or one (1) month (“
              <strong>Initial Term</strong>”), as specified in the applicable
              order form or payment checkout page provided by Swarmia.
              Thereafter, this Agreement shall remain in force for subsequent
              term(s) of the same duration as the Initial Term until terminated
              by either Party by giving a written notice to the other Party at
              least fourteen (14) days before the end of the then-current term,
              email being sufficient, or in the case of the Customer, by
              canceling the Customer’s order via the Service at least fourteen
              (14) days before the end of the then-current term.
            </li>
            <li>
              Each Party may terminate this Agreement for cause with immediate
              effect upon written notice to the other Party if:
              <ol>
                <li>
                  the other Party becomes insolvent, applies for or is
                  adjudicated in bankruptcy or liquidation or corporate
                  restructuring or otherwise ceases to carry on its business; or
                </li>
                <li>
                  the other Party is in material breach of the terms and
                  conditions of this Agreement and fails to remedy such breach
                  within thirty (30) days from the date of receipt of a written
                  notice by the non-defaulting Party, such written notice
                  detailing the breach and the intention to terminate.
                </li>
              </ol>
            </li>
            <li>
              Swarmia shall be entitled to terminate this Agreement with
              immediate effect and without any obligation to pay damages or any
              other liability to the Customer where the Customer has itself used
              or allowed any third party to use the Service contrary to this
              Agreement.
            </li>
            <li>
              Upon termination for whatever reason, except for termination for
              convenience by Swarmia, no paid Fees will be returned by Swarmia,
              and the Customer is obliged to pay the Fees past due at the
              effective date of such termination. In the event that Fees are not
              fully paid when due or the obligations set out in this Agreement
              are not otherwise followed by the Customer, Swarmia reserves the
              right to terminate the Customer's right to use the Service with
              immediate effect.
            </li>
          </ol>
        </li>

        <li>
          <h2>Governing Law and Disputes</h2>
          <ol>
            <li>
              This Agreement shall be governed by and construed in accordance
              with the substantive laws of Finland, excluding its choice of law
              provisions.
            </li>
            <li>
              Any dispute, controversy or claim arising out of or relating to
              this Agreement, or the breach, termination or validity thereof,
              shall be arbitrated in accordance with the Arbitration Rules of
              the Finland Chamber of Commerce, and any resulting award shall be
              final, binding and non-appealable. The seat of arbitration shall
              be Helsinki, Finland, and the language of the arbitration shall be
              English.
            </li>
            <li>
              Notwithstanding anything set out in Section 15.2 above, Swarmia
              shall have the right to claim unpaid Fees in a public court.
            </li>
          </ol>
        </li>

        <li>
          <h2>Other terms</h2>
          <ol>
            <li>
              Swarmia shall be free to use subcontractors in the performance of
              its obligations and exercise of its rights under this Agreement.
              Swarmia shall be liable for the acts and omissions of its
              subcontractors under this Agreement as for its own.
            </li>
            <li>
              Swarmia may use the Customer's name and logo to identify the
              Customer as a customer of Swarmia. Swarmia's use of the name and
              logo does not create any ownership right therein and all rights
              not granted to Swarmia are reserved by the Customer.
            </li>
            <li>
              Neither Party shall be entitled to assign nor transfer all or any
              of its rights, benefits and obligations under this Agreement
              without the prior written consent of the other Party, which
              consent shall not be unreasonably withheld or delayed. Swarmia
              shall, however, have the right to assign this Agreement to its
              Affiliate or in connection with a sale, transfer, merger or
              reorganization of its business or a relevant part thereof.
            </li>
            <li>
              Swarmia shall have the right to update this Agreement at its sole
              discretion. Swarmia shall notify the Customer of such update at
              least thirty (30) days in advance. Should the Customer not accept
              the updated Agreement, the Customer shall have the right to
              terminate this Agreement by a written notice to Swarmia, email
              being sufficient, effective as of the effective date of the new
              Agreement.
            </li>
            <li>
              Any terms and conditions that by their nature or otherwise
              reasonably should survive a cancellation or termination of this
              Agreement shall also be deemed to survive.
            </li>
          </ol>
        </li>
      </ol>
    </Block>
  </Page>
)
